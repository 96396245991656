import { graphql, useStaticQuery } from "gatsby"
import Img from 'gatsby-image'
import React from "react"
import Layout from "../components/layout"
import HeroHeadline from "../components/heroheadline"
import * as hero from '../styles/hero.module.css'
import * as styles from '../styles/alumni.module.css'
import Sectionheader from "../components/sectionheader"

export default function Esports() {
    const data = useStaticQuery(graphql`
        query AlumniHero {
            file(relativePath: {eq: "heroes/AdobeStock_293562203.jpeg"}) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return(
        <Layout>
            <div className={hero.smarthero}>
                <Img fluid={data.file.childImageSharp.fluid} className={hero.smartheroimage} />
                <HeroHeadline h1="Alumni Programs" sub="always learning" />
            </div>
            <div className={styles.who}>
                <div className="container">
                    <div className="row align-items-start">
                        <Sectionheader name="About Alumni Programs" />
                        <div className="col-11 col-sm-8">
                            <p>The vision of Alumni Programs is to empower the alumni, who are our global and community leaders, by strengthening individual success through re-tooling and re-skilling modern skills them with that will create confidence in the work place and society</p>
                            <hr />
                            <p><strong>Fall Classes 2021:</strong> TSU is ready to assist in renewing and enhancing professional careers by offering a course in all programs: agriculture, business education, engineering, computer science, criminal justice, public services, health science, art, music, STEAM, etc.</p>
                            <ul>
                                <li>No application fee required</li>
                                <li>No Transcript required for readmit applications<br />
                                <sub>NOTE: Transcripts will be verified through the TSU records office.</sub>
                                </li>
                            </ul>
                            <p>Special highlighted online courses include "<i>Apple: Everyone Can Code & Create</i>", "<i>App Design</i>", "<i>Apple Stackables</i>" & "<i>The World of eSports</i>"</p>
                        </div>
                        <div className="col-11 col-sm-3">
                            <div className={styles.whocontact}>
                                <ul>
                                    <li>
                                        <a href="https://www.tnstate.edu/graduate/graduatecatalog.aspx" target="_blank" rel="noreferrer" className="btn btn-primary" role="button">View Graduate Catalog</a>
                                    </li>
                                    <li>
                                        <a href="http://go.tnstate.edu/inquiryform" target="_blank" rel="noreferrer" className="btn btn-primary" role="button">Apply Now</a>
                                    </li>
                                    <li>
                                        <a href="https://www.canva.com/design/DAEGArRnpXQ/cxmELKF9VAbcm_jb07TybA/view?utm_content=DAEGArRnpXQ&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink" target="_blank" rel="noreferrer" className="btn btn-primary" role="button">ReAdmit Video Tutorial</a>
                                    </li>
                                    <li>
                                        <a href="https://www.tnstate.edu/hr/forms.aspx#educational" target="_blank" rel="noreferrer" className="btn btn-primary" role="button">TSU Employee Fee Waiver Form</a>
                                    </li>
                                    <li>
                                        <a href="mailto:gpos@tnstate.edu" target="_blank" rel="noreferrer" className="btn btn-primary" role="button">Request More Information</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </Layout>
    )
}